<template>
	<standard-container title="Utenti" description="" admin>
		<!-- header -->
		<template v-slot:header>
			<v-tabs v-model="currentTab" :show-arrows="true" background-color="transparent">
				<v-tab key="tabUsers">
					Utenti
				</v-tab>
				<v-tab key="tabUsersStatus">
					Utenti correntemente connessi
				</v-tab>
			</v-tabs>		
		</template>

		<!-- body slot -->
		<v-row :dense="compactVisualization">
			<v-col cols="12">
				<v-btn v-if="currentTab===0 && fabVisible" fab color="warning" top right absolute title="Aggiungi utente" @click="aggiungiUtente">
					<v-icon>mdi-account-plus</v-icon>
				</v-btn>						
				<v-tabs-items v-model="currentTab">
					<!-- tabUsers -->
					<v-tab-item key="tabUsers">
						<v-row>
							<v-col cols="12">
								<div class="d-block d-md-flex align-center pt-4">
									<v-text-field ref="searchField" v-model="search" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" autofocus prepend-inner-icon="mdi-magnify" label="Ricerca libera" clear-icon="mdi-close-circle" clearable hide-details />
									<v-select v-model="filtraTipoutente" :items="tipiUtente" label="Tipo utente" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" clearable class="ml-0 ml-md-10" hide-details></v-select>
									<v-switch v-model="visualizzaDisattivati" inset label="Visualizza disattivati" color="warning" class="ml-0 ml-md-10 " /> 
								</div>
							</v-col>
							<v-col cols="12">
								<v-data-table 
									:loading="loading"
									:items="usersFiltered" 
									:headers="usersHeaders"
									:sort-by="['RagSociale']" 
									:sort-desc="[false]"
									item-key="Id"
									:search="search"
									show-expand
									:expanded.sync="expanded"
									:single-expand="true"
									@click:row="editUser"
								>
									<template v-slot:[`item.Id`]="{ item }">
										<v-avatar :size="35" :class="item.Stato===0 ? 'opaque' : ''">
											<img v-if="item.Immagine" alt="Avatar" :src="imagesPathPerson + item.Immagine + '?width=70&height=70&mode=crop&anchor=top'">
											<avatar v-else :username="item.RagSociale" :size="35" />
										</v-avatar>
									</template>
									<template v-slot:[`item.RagSociale`]="{ item }">
										<div :class="item.Stato===0 ? 'opaque' : ''">
											<standard-label cssclass="body-1">
												{{ item.RagSociale }} <strong>({{ item.UserName }})</strong>
											</standard-label>
											<standard-label cssclass="caption opaque">
												{{ item.Email }}
											</standard-label>
										</div>
									</template>
									<template v-slot:[`item.IsActive`]="{ item }">
										<v-chip v-if="item.IsActive" x-small color="primary">ATTIVO</v-chip>
										<v-chip v-else x-small color="default">DISATTIVATO</v-chip>
									</template>
									<template v-slot:[`item.Tipouser`]="{ item }">
										<div :class="item.Stato===0 ? 'opaque' : ''">
											<v-chip x-small :color="decodificaTipoUtente(item.Tipouser).color">{{ decodificaTipoUtente(item.Tipouser).text.toUpperCase() }}</v-chip>
										</div>
									</template>
									<template v-slot:[`item.UserName`]="{ item }">
										<div :class="item.Stato===0 ? 'opaque' : ''">
											<div v-if="!item.IsCurrentUser">
												<v-btn v-if="item.IsActive" small color="primary" icon title="resetta password" @click.stop="resetPasswordUtente(item)">
													<v-icon>mdi-shield-refresh</v-icon>
												</v-btn>
												<v-btn v-else small icon color="error" title="elimina utente" @click.stop="eliminaUtente(item)">
													<v-icon>mdi-delete</v-icon>
												</v-btn>
												<v-btn v-if="item.IsActive" class="ml-4" small icon color="warning" title="disattiva utente" @click.stop="disattivaUtente(item)">
													<v-icon>mdi-account-off</v-icon>
												</v-btn>
												<v-btn v-else class="ml-4" small icon color="primary" title="riattiva utente" @click.stop="riattivaUtente(item)">
													<v-icon>mdi-account-check</v-icon>
												</v-btn>
											</div>
										</div>
									</template>
									<template v-slot:expanded-item="{ headers, item }">
										<td :colspan="headers.length" class="tdExtended">
											<div class="pt-4 mb-4">
												<v-simple-table>
													<template v-slot:default>
														<tbody>
															<tr>
																<th>data inserimento</th>
																<td>{{ item.Datainserimento | standardDate }}</td>
															</tr>
															<tr>
																<th>data cessazione</th>
																<td>{{ item.Datacessazione | standardDate }}</td>
															</tr>
															<tr>
																<th>data ultima modifica</th>
																<td>{{ item.DataUltimaModifica | standardDate }}</td>
															</tr>
															<tr>
																<th>data ultimo accesso</th>
																<td>{{ item.DataUltimoAccesso | standardDate }}</td>
															</tr>
															<tr>
																<th>data ultimo ping</th>
																<td>{{ item.DataUltimoPing | standardDate }}</td>
															</tr>
															<tr>
																<th>note</th>
																<td><pre>{{ item.Notes ? item.Notes : '-' }}</pre></td>
															</tr>
														</tbody>
													</template>
												</v-simple-table>
											</div>
										</td>									
									</template>
								</v-data-table>
							</v-col>
						</v-row>
					</v-tab-item>
					<!-- tab utenti connessi -->
					<v-tab-item key="tabUsersStatus">
						<v-simple-table>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-left">
											Utente
										</th>
										<th class="text-left">
											Ultimo login
										</th>
										<th class="text-left">
											Ultimo heartbeat
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in usersStatusFiltered" :key="item.Id">
										<td><strong>{{ item.RagioneSociale }}</strong> ({{ item.UserName }})</td>
										<td>{{ item.DataUltimoAccesso | standardDateWithHour }}</td>
										<td>{{ item.DataUltimoPing | standardHour }}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>

		<!-- debug -->
		<template v-slot:debug>
			<v-row>
				<v-col cols="12" md="6">
					<debug-panel label="users">
						{{ users }}
					</debug-panel>
				</v-col>
			</v-row>
		</template>

		<!-- footer -->
		<template v-slot:footer>
			<v-row class="mt-1">
				<v-col cols="6" sm="2" order="2" order-sm="1">
					<v-btn small class="mt-3" icon title="Ricarica la lista" :disabled="loading" :loading="loading" @click="loadUsers">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</template>

		<!-- inizio dialog utenti -->
		<v-dialog v-model="dialogUser" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialogUser = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor">
					<v-btn icon @click="dialogUser = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ currentUser.New ? 'Nuovo' : 'Modifica' }} utente</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :disabled="loading" :loading="loading" @click="updateUser">
							Save
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row dense>
						<v-col cols="12" lg="4">
							<v-text-field v-model="currentUser.UserName" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Username" />
						</v-col>
						<v-col v-if="!currentUser.IsCurrentUser" cols="12" lg="4">
							<v-select v-model="currentUser.Tipouser" :items="tipiUtente" label="Tipo" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-select>
						</v-col>
						<v-col v-if="!currentUser.IsCurrentUser" cols="12" lg="4">
							<v-select v-model="currentUser.IsActive" :items="statiUtente" label="Stato" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-select>
						</v-col>
						<v-col v-if="currentUser.New" cols="12" lg="12">
							<v-text-field v-model="currentUser.NewPassword" warning :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Password" />
						</v-col>
						<v-col cols="12" lg="12">
							<v-text-field v-model="currentUser.RagSociale" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Ragione sociale" />
						</v-col>
						<v-col cols="12" lg="12">
							<v-text-field v-model="currentUser.Indirizzo" label="Indirizzo" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />
						</v-col>
						<v-col cols="12" lg="6">
							<v-text-field v-model="currentUser.Localita" label="Localita" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />
						</v-col>
						<v-col cols="12" lg="3">
							<v-text-field v-model="currentUser.Provincia" label="Provincia" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />
						</v-col>
						<v-col cols="12" lg="3">
							<v-text-field v-model="currentUser.Cap" label="Cap" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />
						</v-col>
						<v-col cols="12" lg="7">
							<v-text-field v-model="currentUser.Email" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Email" />
						</v-col>
						<v-col cols="12" lg="5">
							<v-text-field v-model="currentUser.Telefono" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Telefono" />
						</v-col>
						<v-col cols="12" lg="12">
							<v-text-field v-model="currentUser.Responsabile" label="Responsabile" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />
						</v-col>
						<v-col cols="12" lg="12">
							<v-text-field v-model="currentUser.ResponsabileClienti" label="ResponsabileClienti" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />
						</v-col>
						<v-col cols="12" lg="12">
							<v-textarea v-model="currentUser.Note" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Note" rows="1" auto-grow />
						</v-col>
					</v-row>
					<v-alert v-if="errorUser" type="error" dense text>
						{{ errorUser }}
					</v-alert>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog utenti -->			


		<loading :loading="loading" />
	</standard-container>
</template>

<script>
import StandardContainer from '@/components/standard-container'
import DebugPanel from '@/components/debug-panel'
import StandardLabel from '@/components/standard-label'
import Loading from '@/components/loading'
import Avatar from 'vue-avatar'
import {Entropy} from 'entropy-string'
export default {
	name: 'AdminUsersPage',
	components: { StandardContainer, DebugPanel, StandardLabel, Loading, Avatar },
	data: () => ({
		currentTab:0,
		fabVisible: true,
		dialogUser: false,
		errorUser: '',
		loading: false,
		search: '',
		filtraTipoutente: 'O',
		expanded: [],
		visualizzaDisattivati: true,
		usersHeaders: [
			{ text: '', value: 'Id', width:50},
			{ text: 'Nome', value: 'RagSociale'},
			{ text: 'Stato', value: 'IsActive'},
			{ text: 'Tipo', value: 'Tipouser'},
			{ text: '', value: 'UserName'}, // colonna farlocca usata per il delete e altro
		],
		tipiUtente: [
			{text:'Organizzato', value:'O', color:'primary'}, 
			{text:'Customer Service', value:'D', color:'info'}, 
			{text:'Superuser', value:'S', color:'warning'}, 
			{text:'Admin', value:'A', color:'error'}
		],
		statiUtente: [
			{text:'Attivo', value:true}, 
			{text:'Disattivato', value:false}
		],

		users: [],
		currentUser: {},
	}),
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		usersStatus() { return this.$store.getters['usersStatus'] },
		usersStatusFiltered() { 
			return this.usersStatus.filter(user => { return user.IsConnected }) 
		},
		usersFiltered() {
			return this.users.filter(item => {
				let filter = true
				if(this.filtraTipoutente) filter = filter && item.Tipouser === this.filtraTipoutente
				if(!this.visualizzaDisattivati) filter = filter && item.IsActive
				return filter
			})
		}
	},
	watch:{
		// eslint-disable-next-line no-unused-vars
		$route (to, from){
			// this.$refs.searchField.focus()
		},
		currentTab(to, from) {
			// giochino sporco per far capire all'utente che il fab (che non si sposta) è realativo alla tab corrente e non globale
			// quindi lo nascondiamo giusto il tempo di fare lo spostamento di tab
			if(to!==from) {
				this.fabVisible = false
				setTimeout(()=>{ this.fabVisible = true },500)
			}
		}
	},
	mounted() {
		this.loadUsers()
	},
	methods: {
		// carica i dati dallo store (disacoppiandoli) e poi cicla la tabella risultante per caricare le decodifiche necessarie
		initData() {
		},
		loadUsers() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/Users/ListUsers'}).then(data => {
				this.users = data.Users
				this.loading = false
			}).catch(error => {
				console.error(error)
				this.loading = false
			})
		},
		// -----------------------------------------------------
		async aggiungiUtente() {
			var negativeId = 0
			await this.$store.dispatch('getNegativeIncrementId').then(id => { negativeId = id }) // chiamata sincrona allo store !
			const entropy = new Entropy()
			var newUtente = {
				Id: negativeId,
				IsActive: true,
				Tipouser: 'O',
				IsCurrentUser: false,
				New: true,
				NewPassword: entropy.string() // suggerisce pwd sicura
			}
			this.editUser(newUtente)
		},
		eliminaUtente(item) {
			this.$swal('ATTENZIONE!', 'Sei sicuro di voler eliminare l\'utente "'+item.RagSociale+'" ?', 'warning', { buttons: { ok: 'Si', cancel: 'No' }}).then(result => {
				if (result === 'ok') {
					this.loading = true
					this.$store.dispatch('genericApiPost', {apiUrl: '/Users/DeleteUser', value: item}).then(data => {
						this.users = data.Users
						this.loading = false
					}).catch(error => {
						console.error(error)
						this.loading = false
					})
				}
			})
		},
		resetPasswordUtente(item) {
			this.$swal({
				title: 'Reset password',
				text: 'Digita la nuova password per l\'utente "'+item.RagSociale+'":',
				icon: 'warning',
				content: 'input',
			}).then((result) => {
				// console.warn('resetPasswordUtente', result)
				if (result) {
					item.NewPassword = result
					this.loading = true
					this.$store.dispatch('genericApiPost', {apiUrl: '/Users/ResetUserPassword', value: item}).then(data => {
						this.users = data.Users
						this.loading = false
					}).catch(error => {
						console.error(error)
						this.loading = false
					})
				}
			})
		},
		editUser(item) {
			this.$set(this, 'currentUser', JSON.parse(JSON.stringify(item)))
			this.dialogUser = true
		},
		updateUser() {
			this.loading = true
			this.errorUser = ''
			this.currentUser.NewState = this.currentUser.IsActive
			this.$store.dispatch('genericApiPost', {apiUrl: '/Users/SaveUser', value: this.currentUser}).then(data => {
				this.users = data.Users
				this.loading = false
				this.dialogUser = false
			}).catch(error => {
				console.error(error)
				this.errorUser = error
				this.loading = false
			})
		},
		decodificaTipoUtente(tipoUtente) {
			let result = this.tipiUtente.find( tipo => {
				return tipo.value === tipoUtente
			})
			return result ? result : {text:'-', value:'-', color:'black'}
		},
		disattivaUtente(user){
			this.loading = true
			this.errorUser = ''
			this.$store.dispatch('genericApiPost', {apiUrl: '/Users/DisableUser', value: user}).then(data => {
				this.users = data.Users
				this.loading = false
			}).catch(error => {
				console.error(error)
				this.errorUser = error
				this.loading = false
			})
		},
		riattivaUtente(user){
			this.loading = true
			this.errorUser = ''
			this.$store.dispatch('genericApiPost', {apiUrl: '/Users/EnableUser', value: user}).then(data => {
				this.users = data.Users
				this.loading = false
			}).catch(error => {
				console.error(error)
				this.errorUser = error
				this.loading = false
			})
		},
	}
}
</script>


<style scoped lang="less">
.tdExtended {

	.tblUtenti {
		td {
			padding:0;
			height: auto;
		}
		tr.invisibile {
			td {
				opacity: 0.5;
				text-decoration: line-through;
			}
		}
	}
}
.monospaced {
	font-family: monospace;
}
.opaque {
	opacity: 0.5 !important;	
}
</style>